import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend({
  
  async model(params){
    let weapons = [];
    let charactersheet = await this.store.findRecord('charactersheet', params.charactersheet_id );
    let characterWeapons = charactersheet.weaponsets.sets;

    for (let set = 0; set < characterWeapons.length; set++) {      
      let weapon = await this.store.findRecord('weapons', characterWeapons[set].weaponID);
      weapons.push({fromPlayer: characterWeapons[set], fromWeapon: weapon})
    }
    
    return hash({
      charactersheet: charactersheet,
      skills: this.store.findAll('skill'),
      skillcategories: this.store.findAll('skillcategories'),
      skillsubcategories: this.store.findAll('skillsubcategories'),
      chrSkills: [],
      realtimeSkillPoints: null,
      weapons
    })      
  },

  setupController(controller, models){ 
    controller.set('chrSkills', models.chrSkills);
    controller.set('weapons', models.weapons);
    controller.set('realtimeSkillPoints', models.realtimeSkillPoints);
    controller.set('charactersheet', models.charactersheet);
    controller.set('skills', models.skills);
    controller.set('skillcategories', models.skillcategories);
    controller.set('skillsubcategories', models.skillsubcategories);
  },
});
