import Component from '@ember/component';
import { inject as service } from "@ember/service";
import { getOwner } from '@ember/application';


export default Component.extend({
  store: service(),
  inventory: null,
  showRegular: false,

  didReceiveAttrs() {
    if (this.get("modalStatus") === true) {
      let inventoryID = this.get('inventoryID');
      let model = this.get('store').findRecord('inventory', inventoryID);
      this.set('inventory', model);
    } else if ((getOwner(this).lookup('controller:application').currentPath == 'inventory.char')) {
      this.set('showRegular', true);
    }
  },

  actions: {
    modGold(operator, amount){
      var inventory = this.get('inventory');
      var gold = null;
      try {
        gold = inventory.belt.pouch;
        
      } catch (error) {
        gold = inventory.get('belt.pouch')
      }
      
      if(operator == "add" && gold < 201 - amount){
        gold = Number(gold) + Number(amount);
      }
      else if(operator == "sub" && gold > -1 + amount){
        gold = Number(gold) - Number(amount);
      }

      inventory.set("belt.pouch", gold)

      try {
        inventory.save();
      } catch (error) {
        inventory.then(function(x){
          x.save();
        })
      }
    }
  }
});
