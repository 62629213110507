import { helper } from '@ember/component/helper';

export function pos(params/*, hash*/) {
  if(params[0] > 0){
    return true;
  }
  else{
    return false;
  }  
}

export default helper(pos);
