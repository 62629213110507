import DS from 'ember-data';

export default DS.Model.extend({
  block : DS.attr('boolean'),
  brace : DS.attr(),
  damage : DS.attr(),
  dmgtype : DS.attr(),
  name : DS.attr(),
  parry : DS.attr('boolean'),
  qualities : DS.attr(),
  special : DS.attr(),
  stat : DS.attr(),
  type : DS.attr()
});
