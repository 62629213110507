import Route from '@ember/routing/route';

export default Route.extend({
	
	model(){
		return this.store.findRecord('world','-LYd-Ux1nPeowR8jZvXX');
	},

	actions:{
		buildWorld: function(){
			let ethelheldSanctuary = this.get('controller').get('ethelheldSanctuary');			
			this.store.findRecord('world','-LYd-Ux1nPeowR8jZvXX').then(function(world){				
				if(ethelheldSanctuary !== null){
					world.set('ethelheldSanctuary', ethelheldSanctuary);
				}
				world.save();				
			})
	
		}
	}
	
});
