import DS from 'ember-data';

export default DS.Model.extend({
    tree: DS.attr(),
    tier: DS.attr(),
    rank: DS.attr(),
    points: DS.attr(),
    type: DS.attr(),
    title: DS.attr(),
    desc: DS.attr(),    
    cooldown: DS.attr(),
    effects: DS.attr(),
});
