import Component from '@ember/component';
import { getOwner } from '@ember/application';

export default Component.extend({
    tagName: "",

    actions: {
        setSelected(){
            if (getOwner(this).lookup('controller:application').currentPath == 'charactersheet.add') {
                this.set('currentlySelected', this.subCategory.name);
                switch(this.category.name) {
                    case 'Soul Paths':
                        this.set('person.soulpath', this.subCategory.name);
                        break;
                    case 'Bloodlines':
                        this.set('person.bloodline', this.subCategory.name);
                        break;
                    case 'Profession':
                        this.set('person.profession', this.subCategory.name);
                        break;
                    case 'Weaponsets':
                        this.set('person.weaponsets', this.subCategory.name);
                        break;
                } 
            }          
        }
    },
});

