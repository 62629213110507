import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend({

  async model(params){
    let skills = await this.store.findAll('skill');
    let cID = params.profession_id;
    let skillsubcategories = await this.store.findAll('skillsubcategories')
    let fskillsubcategories = skillsubcategories.filter(mod => {
      return mod.get('name') === cID;
    });
  
    if(params.charParam == null){
      return hash({
        skills: skills,
        skillsubcategories: fskillsubcategories,
        cID: cID,
        chrSkills: [],
      });
    }
    else{      
      let character = await this.store.findRecord('charactersheet', params.charParam);
      return hash({
        skills: skills,
        character: character,
        chrSkills: [],
        skillsubcategories: fskillsubcategories,
        cID: cID,

      });
    } 
  },

  setupController(controller, models) {
    controller.set('skills', models.skills);
    controller.set('cID', models.cID);
    controller.set('chrSkills', models.chrSkills);
    controller.set('character', models.character);
    controller.set('skillsubcategories', models.skillsubcategories);
  }

});