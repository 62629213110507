import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  isModal: false,

  didReceiveAttrs(){
    if (this.weapon.fromPlayer.status === "main" 
    || this.weapon.fromPlayer.status === "off"
    || this.weapon.fromPlayer.status === "summoned"){
      this.setVisible = true;
    }
  },

  actions: {
    showModal(){
      this.toggleProperty('isModal');
    }
  }
});
