import Component from '@ember/component';
import { inject as service } from '@ember/service';


export default Component.extend({
  tagName: '',
  setNeeded: false,
  setCanHave: false,
  store: service(),

  didReceiveAttrs() {
    if (this.charParam) {
      if (this.chrSkills.length == 0) {
        for (let x = 0; x < this.character.skills.length; x++) {
          this.chrSkills.push(this.character.skills[x]);
        }
      } 
    }

    switch (this.action) {
      case "filter":
        this.send('filterSkills');
        break;

      case "showAll":
        this.send('showSkills');
        break;

      default:
        break;   
    }
  },

  didUpdateAttrs(){
    if(this.get('setVisible')){
      this.set('setVisible', false);
    }
  },

    actions: {
      filterSkills() {
        let skill = this.get('skill');
        let chrSkills = this.get('chrSkills');
        let filter = this.get('filter');

        for (let chrSkill of chrSkills) {
          if (chrSkill == skill.id && skill.tree == filter) {
            this.set('setVisible', true);
          } 
        }
      },

      showSkills() {
        let skill = this.get('skill');
        if (skill.tree === this.filter) {
          this.set('setVisible', true);
          if (this.charParam) {
            this.send('setStatus');
            return
          }
        }
      },

      addSkillChar(skill){
        let character = this.get('character');
        character.skills.push(skill.id);
        character.set('experience.usedSP', Number(character.experience.usedSP) + Number(skill.points));
        character.set('experience.unUsedSP', Number(character.experience.unUsedSP) - Number(skill.points));
        character.save().then(
          this.set('setNeeded', false),
          this.set('setOwned', true)
          );
      },

      setStatus() {
        
        let unUsedSP = this.get('character').experience.unUsedSP;
        this.set('setNeeded', true);

        for (let chrSkill of this.chrSkills) {
          if (chrSkill === this.skill.id) {
            if (this.action === "showAll") {
              this.set('setOwned', true);
              this.set('setNeeded', false);
            }
          } 
          else { 
            if (this.skill.points <= unUsedSP) {
              this.set('setCanHave', true);
            } 
          }
          if (this.action === "showAll") {
            let remove = [chrSkill];
            this.chrSkills = this.chrSkills.filter(x => !remove.includes(x));
          }
        }
      },
    }
});
