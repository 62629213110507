import Component from '@ember/component';
import { inject } from '@ember/service';
import { getOwner } from '@ember/application';

export default Component.extend({
    tagName: '',
    store: inject(),
    charactersheet: null,
    
    didInsertElement(){
        this.set('charactersheet', this.get('store').findAll('charactersheet'));

        if (getOwner(this).lookup('controller:application').currentPath.includes('world.') && (getOwner(this).lookup('controller:application').currentPath) !== 'world.index') {
            this.set('setHide', true);
        }
    }, 

    willDestroyElement(){
        if(this.get('setHide')){
            this.set('setHide', false);
        }
    }
});
