import Component from '@ember/component';
import { getOwner } from '@ember/application';
import { inject as service } from "@ember/service";

export default Component.extend({
  store: service(),

  didReceiveAttrs() {
    if (this.get("modalStatus") === true) {
      let inventoryID = this.get('inventoryID');
      let model = this.get('store').findRecord('inventory', inventoryID);
      this.set('inventory', model);
    } else if ((getOwner(this).lookup('controller:application').currentPath == 'inventory.char')) {
      this.set('showRegular', true);
    }
  },

  actions: {
    modItem(operator, field, maxamount){
      var inventory = this.get('inventory');
      var calcInventory = null;

      try {
        calcInventory = inventory.essentials
      } catch (error) {
        calcInventory = inventory.get('essentials');
      }
      var item = eval('calcInventory.'+field);
      var finalValue = null;
      
      if(operator == "add" && item < maxamount){
        finalValue = item + 1;
      } else if(operator == "sub" && item > 0){
        finalValue = item - 1;
      } else {
        finalValue = item;
      }
      
      inventory.set("essentials."+field, finalValue);
      try {
        inventory.save();
      } catch (error) {
        inventory.then(function(x) {
          x.save();
        });
      }
    }
  }
});
