import Route from '@ember/routing/route';

export default Route.extend({
  
  actions:{
    addStory: function(){
      let story = this.store.createRecord('story', {        
        storyTitle: "azaine-origin",
        storyAtt1: "0",
        storyAtt2: "0",
        storyAtt3: "0",
        storyAtt4: "0",
        storyAtt5: "0",
        storyAtt6: "0",
        storyAtt7: "0",
        storyAtt8: "0",
        storyAtt9: "0",
        storyAtt10: "0"
      });
      story.save();
    }   
  }
});
