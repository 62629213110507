import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend({
  async model(){

    let skillcategories = this.store.findAll('skillcategories');
    let skillsubcategories =  this.store.findAll('skillsubcategories');

    return hash({
      skillcategories: skillcategories,
      skillsubcategories: skillsubcategories,
    });
  },
  setupController(controller, models) {
    controller.set('skillcategories', models.skillcategories);
    controller.set('skillsubcategories', models.skillsubcategories);
  }

});