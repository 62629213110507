import DS from 'ember-data';

export default DS.Model.extend({
	storyTitle: DS.attr(),
	storyAtt1: DS.attr(),
	storyAtt2: DS.attr(),
	storyAtt3: DS.attr(),
	storyAtt4: DS.attr(),
	storyAtt5: DS.attr(),
	storyAtt6: DS.attr(),
	storyAtt7: DS.attr(),
	storyAtt8: DS.attr(),
	storyAtt9: DS.attr(),
	storyAtt10: DS.attr()
});
