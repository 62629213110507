import Component from '@ember/component';

export default Component.extend({
  tagName:'',
  isModal:false,
  saveSucess: false,
  
  actions: {
    showModal(){
      this.toggleProperty('isModal');
    },
  }
});
