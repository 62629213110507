import EmberRouter from '@ember/routing/router';
import config from './config/environment';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL
});

Router.map(function() {
  this.route('index', {path:'/'});
  this.route('rules', function(){
    this.route('index', {path:'/'});
  });
  this.route('skills', function(){
    this.route('profession-details', {path:'/:profession_id'});
    this.route('overview');
    this.route('add');
    this.route('index', {path:'/'});
    this.route('activities', function(){
      this.route('index', {path:'/'});
      this.route('acrobatics');
      this.route('animalhandling');
      this.route('arcanomancy');
      this.route('athletics');
      this.route('diplomacy');
      this.route('forging');
      this.route('investigation');
      this.route('lore');
      this.route('medicine');
      this.route('perception');
      this.route('piety');
      this.route('stealth');
      this.route('survival');
      this.route('thievery');
    })
  });
  this.route('story', function(){
    this.route('index', {path: '/'});
    this.route('jyn', function(){
      this.route('origin');
    });
    this.route('vadir', function(){
      this.route('origin');
    });
    this.route('azaine', function(){
      this.route('origin');
    })
  })
  this.route('charactersheet', function(){
    this.route('index', {path:'/'});
    this.route('add');
    this.route('view', {path:'/:charactersheet_id'});
  });
  this.route('world', function(){
    this.route('index', {path:'/'})
    this.route('ethelheld-sanctuary', function(){
      this.route('index', {path:'/'})
      this.route('warroom');
      this.route('thelodge');
      this.route('traininggrounds');
      this.route('warehouse');
      this.route('mineentrance');
      this.route('beastiary');
      this.route('pilgrims1');
      this.route('pilgrims2');
      this.route('inn');
      this.route('oldwell');
      this.route('statue');
      this.route('grove');
      this.route('cemetary');
      this.route('goddressroad');
      this.route('ethelheldroad');
      this.route('sanctuary');
      this.route('forge');
      this.route('delnorvalleymap');
      this.route('nyserancalendar');
      this.route('soulwell');
      this.route('library');
    });
    this.route('characters', function(){
      this.route('taman');
      this.route('theforeman');
      this.route('cimarron');
      this.route('marrick');
      this.route('altamont');
      this.route('falagar');
      this.route('jackson');
      this.route('willem');
      this.route('tobias');
      this.route('grito');
      this.route('oldman');
    });
  });
  this.route('inventory', function(){
    this.route('index',{ path: '/' })
    this.route('char', { path: '/:inventory_id' });
  });
  this.route('config');
});


export default Router;
