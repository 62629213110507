import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
    tagName: '',
    store: service(),
    curLevel: null,
    curTotalSpSpent: null,

    async didInsertElement() {
        let levels = [ 0, 1, 300, 900, 2700, 6500, 14000, 23000, 34000, 48000, 64000, 85000, 100000, 120000, 140000, 165000, 195000, 225000, 265000, 305000, 355000 ];
        let curXP = this.xp;
        let calcLevel = null;

        for (calcLevel = 0; calcLevel < levels.length; calcLevel++) {
            if (curXP < levels[calcLevel]) {
                calcLevel = calcLevel - 1;
                break;
            }
        }
        
        this.set('curLevel', calcLevel);
    }
});
