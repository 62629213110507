import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import EmberObject from '@ember/object';

export default Route.extend({

  async model(){
    let Person = EmberObject.extend({
      soulpath: null,
      bloodline: null,
      profession: null,
      weaponsets: null,
    });

    let person = Person.create()
    let skillcategories = this.store.findAll('skillcategories');
    let skillsubcategories =  this.store.findAll('skillsubcategories');

    return hash({
      skillcategories: skillcategories,
      skillsubcategories: skillsubcategories,
      person: person,
    });
  },

  setupController(controller, models) {
    controller.set('person', models.person);
    controller.set('skillcategories', models.skillcategories);
    controller.set('skillsubcategories', models.skillsubcategories);
  },

  actions:{

    addCharacter() {
      let person = this.modelFor('charactersheet.add').person

      let newInventory = this.store.createRecord('inventory', {
        character: this.get('controller').get('name'),
        belt: {
          pouch1: 0,
          pouch2: 0,
          pouch3: 0,
          pouch4: 0
        },
        backpack: {
          item1: "",
          item2: "",
          item3: "",
          item4: "",
          item5: "",
          item6: "",
          item7: "",
          item8: "",
          type: "normal"
        },
        essentials: {
          ammunition: 0,
          bedroll: 0,
          flint: 0,
          food: 0,
          healerskit: 0,
          rope: 0,
          string: 0,
          torches: 0,
          waterskin: 0
        },
        other: 0
      });

      let charactersheet = this.store.createRecord('charactersheet', {
        name: this.get('controller').get('name'),
        str: this.get('controller').get('str'),
        dex: this.get('controller').get('dex'),
        con: this.get('controller').get('con'),
        int: this.get('controller').get('int'),
        wis: this.get('controller').get('wis'),
        cha: this.get('controller').get('cha'),
        inventory: newInventory.id,
        soulpath: {
          name: person.soulpath,
          tier: 1,
        },
        bloodline: {
          name: person.bloodline,
          tier: 1,
          speed: 30,
          hitdie:10,
        },
        profession: {
          name: person.profession,
          tier: 1,
        },
        weaponsets: {
          name: person.weaponsets,
          tier: 1,
          sets:[
          {
            type: person.weaponsets,
            mainhand: "",
            offhand: "",
            proficiency: "",
            damage: [""],
            dmgtype:[""],
            stat: [""],
            affinity: 1,
            block: false,
            parry: false,
            brace: "",
            attunement: 0,
            qualities: [""],
            special: ""
          },
          {
            type:"",
            mainhand: "",
            offhand: "",
            proficiency: "",
            damage: [""],
            dmgtype:[""],
            stat: [""],
            affinity: "",
            block: "",
            parry: "",
            brace: "",
            attunement: "",
            qualities:[""],
            special: ""
          }]
        },
        activities: [
        {
          name: "acrobatics",
          stat: "dex",
          tier: 0,
        },
        {
          name: "animal handling",
          stat: "cha",
          tier: 0,
        },
        {
          name: "arcanomancy",
          stat: "int",
          tier: 0,
        },
        {
          name: "athletics",
          stat: "str",
          tier: 0,
        },
        {
          name: "diplomacy",
          stat: "cha",
          tier: 0,
        },
        {
          name: "forging",
          stat: "con",
          tier: 0,
        },
        {
          name: "investigation",
          stat: "int",
          tier: 0,
        },
        {
          name: "lore",
          stat: "int",
          tier: 0,
        },
        {
          name: "medicine",
          stat: "wis",
          tier: 0,
        },
        {
          name: "perception",
          stat: "wis",
          tier: 0,
        },
        {
          name: "piety",
          stat: "wis",
          tier: 0,
        },
        {
          name: "stealth",
          stat: "dex",
          tier: 0,
        },
        {
          name: "survival",
          stat: "wis",
          tier: 0,
        },
        {
          name: "thievery",
          stat: "dex",
          tier: 0,
        }],
        hitpoints: {
          armorset: {
            body: "",
            armorhp: "",
            brace:"",
            dodge: false,
            maxdex: "",
            movementpen:0,          
            otherpenalties:[""],
            fireresistance:0,
            frostresistance:0
          },
          basehp: 14,
          currenthp:12,
          hitdice:4,          
        },
        attunement: {
          current: 0,
          fireresistance: 0,
          frostresistance:0
        },
        experience: {
          level: 1,
          xp: 0,
          nextlvlxp:6500,
          unspentskillpoints: 5
        },
        conditions:[
        {
          "condition": "",
          "duration": ""
        }],
      });

      

      charactersheet.save().then(function(){
        newInventory.save();
      });
    },
  }
});
