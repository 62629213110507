import DS from 'ember-data';

export default DS.Model.extend({
	name: DS.attr(),
	abilities: DS.attr(),
	activities: DS.attr(),
	activitynames: DS.attr(),
	attunement: DS.attr(),
	bloodline: DS.attr(),
	conditions: DS.attr(),
	experience: DS.attr(),
	hitpoints: DS.attr(),
	inventory: DS.attr(),
	profession: DS.attr(),
	skills: DS.attr(),
	soulpath: DS.attr(),
	weaponsets: DS.attr()
});
