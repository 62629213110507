import Controller from '@ember/controller';

export default Controller.extend({
  unUsedSP: 0,
  showPouch: false,
  showBackpack: false,
  showEssentials: false,
  
  actions: {
    
    loadModal(inventorySegment){
      let pouch = this.get('showPouch');
      let backpack = this.get('showBackpack');
      let essentials = this.get('showEssentials');

      switch (inventorySegment) {
        case "pouch":
          this.toggleProperty("showPouch", pouch);
          break;
        case "backpack":
          this.toggleProperty("showBackpack", backpack);
          break;
        case "essentials":
          this.toggleProperty("showEssentials", essentials);
          break;
        case "none":
          break;

        default:
          break;
      }
    }
  }
});
