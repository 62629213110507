import { helper } from '@ember/component/helper';

export function groupBy([list, key, value]/*, hash*/) {
    function group(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
             let  key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }

    let  grouped = group(list, keyGetter => keyGetter[key]);
    
    return  grouped.get(value);
}

export default helper(groupBy);
