import Controller from '@ember/controller';

export default Controller.extend({
  actions: {

    originRiel(model){
      model.set('storyAtt1', 1);
      model.save();
    },

    originFrontier(model){
      model.set('storyAtt1', 2);
      model.save();    
    },

    originNysera(model){
      model.set('storyAtt1', 3);
      model.save();  
    },

    roleOne(model){
      model.set('storyAtt2', 1);
      model.save(); 
    },

    roleTwo(model){
      model.set('storyAtt2', 2);
      model.save(); 
    },

    saveStory(model){
      model.setProperties({
        storyAtt3: this.get('model.storyAtt3'),
        storyAtt4: this.get('model.storyAtt4'),
        storyAtt5: this.get('model.storyAtt5'),
        storyAtt6: this.get('model.storyAtt6')
      });
      model.save(); 
    },

    resetStory(model){
      model.setProperties({
        storyAtt1: "",
        storyAtt2: "",
        storyAtt3: "",
        storyAtt4: "",
        storyAtt5: "",
        storyAtt6: "",
        storyAtt7: "",
        storyAtt8: "",
        storyAtt9: "",
        storyAtt10: ""
      });
      model.save(); 
    }

  }
});
