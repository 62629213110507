import Component from '@ember/component';
import { inject as service } from "@ember/service";
import { getOwner } from '@ember/application';

export default Component.extend({
  store: service(),
  inventory: null,

  didReceiveAttrs() {
    if (this.get("modalStatus") === true) {
      let inventoryID = this.get('inventoryID');
      let modalModel = this.get('store').findRecord('inventory', inventoryID);
      this.set('inventory', modalModel);
    } else if ((getOwner(this).lookup('controller:application').currentPath == 'inventory.char')) {
      this.set('showRegular', true);
    }
  },

  actions: {
    updateBackpack() {
      let inventory = this.get('inventory')
      
      inventory.then(function(x){
        x.save();
      });
    }
  }
});
