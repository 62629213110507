import { helper } from '@ember/component/helper';

export function noeq(params/*, hash*/) {
  if(params[0] != params[1]){
    if(params[0] != ""){
      return true;
    }
    else{
      return false;
    }
  }
  else{
    return false;
  }  
}

export default helper(noeq);
