import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  store: service(),
  maxHP: computed('character.hitpoints.{basehp,armorset.armorhp}', function(){
    return Number(this.character.hitpoints.basehp) + Number(this.character.hitpoints.armorset.armorhp);
  }),

  actions: {
    async modAttunement(modification){      
      if(modification === "add"){
        this.character.attunement.current = this.character.attunement.current + 1;

      } else if (modification === "sub") {
        this.character.attunement.current = this.character.attunement.current - 1;
      }

      this.character.set('attunement.totalFire', ((this.character.attunement.current/14) * 100));
      this.character.set('attunement.totalFrost', 100 - this.character.attunement.totalFire);

      this.character.save();
    },

    modHP (modification){
      if(modification === "add"){
        this.character.set('hitpoints.currenthp', this.character.hitpoints.currenthp + 1);
      } else if (modification === "sub"){
        if (this.character.hitpoints.currenthp > 0) {
          this.character.set('hitpoints.currenthp', this.character.hitpoints.currenthp - 1);
        }
      }
      
      this.character.save();
    }
  }
});
