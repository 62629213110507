import Controller from '@ember/controller';

export default Controller.extend({

  actions: {

    addSkillChar(skill){
      let character = this.get('character');
      character.skills.push(skill);
      character.save();
    }

  }
});
