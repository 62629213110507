import Controller from '@ember/controller';

export default Controller.extend({
  actions:{
    modGold(operator, amount){
      let gold = this.model.belt.pouch;
      if(operator == "add" && gold < 201 - amount){
        gold = Number(gold) + Number(amount);
        this.model.set("belt.pouch", gold);
        this.model.save();
      }
      else if(operator == "sub" && gold > -1 + amount){
        gold = Number(gold) - Number(amount);
        this.model.set("belt.pouch", gold);
        this.model.save();
      }
    },

    modItem(operator, field, maxamount){
      let item = eval("this.model.essentials[0]."+field);
      if(operator == "add" && item < maxamount){
        this.model.set("essentials"+field, item++);
        this.model.save();
      }
      else if(operator == "sub" && item > 0){
        this.model.set("essentials"+field, item--);
        this.model.save();
      }
    },

    updateBackpack(){
      this.model.save();
    } 
  }
});
