import Route from '@ember/routing/route';

export default Route.extend({
  model(){
    return this.store.findAll('skill');
  },

  actions:{
    saveSkills(model){
      model.save();
    }
  }

});
