import Controller from '@ember/controller';

export default Controller.extend({
  actions: {

    setStoryAtt(model, storyAtt, value){
      let story = "storyAtt" + storyAtt;
      model.set(story, value);
      model.save(); 
    },

    saveStory(model){
      model.setProperties({
        storyAtt3: this.get('model.storyAtt3'),
        storyAtt4: this.get('model.storyAtt4'),
        storyAtt5: this.get('model.storyAtt5'),
        storyAtt6: this.get('model.storyAtt6')
      });
      model.save(); 
    },

    resetStory(model){
      model.setProperties({
        // storyAtt1: "",
        storyAtt2: "",
        storyAtt3: "",
        storyAtt4: "",
        storyAtt5: "",
        storyAtt6: "",
        storyAtt7: "",
        storyAtt8: "",
        storyAtt9: "",
        storyAtt10: ""
      });
      model.save(); 
    }

  }
});
