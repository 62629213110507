import Component from '@ember/component';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  isModal: false,
  showSaveAlert: false,

  didReceiveAttrs(){
    if ((getOwner(this).lookup('controller:application').currentPath == 'config')) {
      this.set('showRegular', true);
    } else {
      this.set('isModal', true)
    }
  },
  
  actions: {
    async addWeapon(){
      let isModal = this.get('isModal');
      
      if (isModal) {
        let model = this.get('store');
  
        var newWeaponModal = model.createRecord('weapons', {
          name: this.get('weaponNameModal'),
          block: this.get('blockModal'),
          brace: this.get('braceModal'),
          damage: this.get('damageModal'),
          dmgtype: this.get('dmgtypeModal'),
          parry: this.get('parryModal'),
          qualities: this.get('qualitiesModal'),
          special: this.get('specialModal'),
          stat: this.get('statModal'),
          type: this.get('typeModal')
        });

        if (this.get('characterID')){
          let newCharacterWeapon = await model.findRecord('charactersheet', this.get('characterID'))
             newCharacterWeapon.weaponsets.sets.push({
                affinity: 0,
                attunement: 0,
                proficiency: 0,
                status: "stored",
                weaponID: newWeaponModal.id
              });
              newCharacterWeapon.save().then(
                newWeaponModal.save()
              );
        }

    } else {
      let model = this.get('store');
      var newWeapon = model.createRecord('weapons', {
        name: this.get('weaponName'),
        block: this.get('block'),
        brace: this.get('brace'),
        damage: this.get('damage'),
        dmgtype: this.get('dmgtype'),
        parry: this.get('parry'),
        qualities: this.get('qualities'),
        special: this.get('special'),
        stat: this.get('stat'),
        type: this.get('type')
      })
      newWeapon.save()
      }
    }
  }
});

{/* <WeaponAdd 
@addWeapon={{action "addWeapon"}}
@model={{this.weapons}} /> */}
