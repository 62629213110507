import Controller from '@ember/controller';

export default Controller.extend({
  actions:{
    addBloodline(){
      let newBloodline = this.store.createRecord('bloodline', {
          name: this.get('name'),
          hitdie: this.get('hitdie'),
          speed: this.get('speed'),
          tier: this.get('tier')
      });

      newBloodline.save();
    }, 

    addWeapon(newWeapon){
      newWeapon.save();
    }
}
});