import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend({
    async model(){
        let skillcategories = this.store.findAll('skillcategories');
        let weapons = this.store.findAll('weapons');
    
        return hash({
          skillcategories: skillcategories,
          weapons: weapons
        });
      },
    
      setupController(controller, models) {
        controller.set('person', models.person);
        controller.set('weapons', models.weapons);
      },
});
