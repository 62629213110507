import DS from 'ember-data';

export default DS.Model.extend({
  character: DS.attr(),
  characterName: DS.attr(),
  belt: DS.attr(),
  backpack: DS.attr(),
  essentials: DS.attr(),
  other: DS.attr()
});
