import Controller from '@ember/controller';

export default Controller.extend({
  sendGoldChar: null,
  goldRemainder: null,

  init() {
    this._super(...arguments);
    this.sortBy = ['character:asc'];
  },

  actions:{
    addInventory: function(){
      let inventory = this.store.createRecord('inventory', {        
        character: "Caorn",
        belt: [{
          pouch1: 11,
          pouch2: 0,
          pouch3: 0,
          pouch4: 0
        }],
        backpack: [{
          type: "normal",
          item1: "0",
          item2: "0",
          item3: "0",
          item4: "0",
          item5: "0",
          item6: "0",
          item7: "0",
          item8: "0"
        }],
        essentials: [{
          food: 2,
          waterskin: 1,
          healerskit: 2,
          torches: 0,
          rope: 0,
          string: 0,
          flint: 0,
          bedroll: 1,
          ammunition: 0
        }],
        other: [0],
      });
      inventory.save();
    },

    goldCharSelect: function(value){
      this.set('sendGoldChar', value);
    },

    sendGold: async function(gold){
      let character = this.get('sendGoldChar');
      let inventory = await this.store.findRecord('inventory', character );
      let curGold = inventory.belt.pouch;
      let newGold = +curGold + +gold;

      if(newGold >= 0 && newGold < 201){
        inventory.set("belt.pouch", newGold);
        inventory.save();
        this.set('goldRemainder', 'success!');
      }
      else if(newGold > 200){
        inventory.set("belt.pouch", 200);
        inventory.save();
        this.set('goldRemainder', +newGold - +200 + "gp left over");
      }
      else if(newGold < -1){
       this.set('goldRemainder', "not enough gp"); 
      }
    },

    quickMod: async function(character, operator, field, maxamount){
      let inventory = await this.store.findRecord('inventory', character );
      if(field == "gold"){
        let curItem = inventory.belt.pouch;      
        if(operator == "add" && curItem < 200){
          inventory.set("belt.pouch", curItem + 1);
          inventory.save();
        }
        else if(operator == "sub" && curItem > 0){
          inventory.set("belt.pouch", curItem - 1);
          inventory.save(); 
        }
      }
      else{
        let item = eval("inventory.essentials."+field);
        if(operator == "add" && item < maxamount){
          inventory.set("essentials."+field, item + 1);
          inventory.save();
        }
        else if(operator == "sub" && item > 0){
          inventory.set("essentials."+field, item - 1);
          inventory.save();
        }
      }
    }

  }

});
