import Route from '@ember/routing/route';

export default Route.extend({

  actions:{
    addSkill: function(){
      let skill = this.store.createRecord('skill', {
        tree: this.get('controller').get('tree'),
        tier: this.get('controller').get('tier'),
        rank: this.get('controller').get('rank'),
        points: this.get('controller').get('points'),
        type: this.get('controller').get('type'),
        title: this.get('controller').get('title'),
        desc: this.get('controller').get('desc')
      });
      skill.save();
    }       
  }
});
